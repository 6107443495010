import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
0% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 0;
}
4.9% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 0;
}
5% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 1;
}
100% {
  top: 0px;
  left: 0px;
  width: 72px;
  height: 72px;
  opacity: 0;
}
`;

export const LoadingContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 50vh;
    background-color: ${theme.colors.white};
    width: 100%;

    div {
      max-width: 48px;
    }
  `}
`;

export const Loading = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    & div {
      position: absolute;
      border: 4px solid ${theme.colors.primary['900']};
      opacity: 1;
      border-radius: 50%;
      animation: ${spin} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    & div:nth-child(2) {
      animation-delay: -0.5s;
    }
  `}
`;
